<template>
  <v-container fluid-container>
    <v-data-iterator
      :footer-props="{
        'items-per-page-options': [4, 8, 12, 24, 40, 100, 500, allItem],
      }"
      :items-per-page="4"
      :items="items"
      ref="dataTable"
      @update:page="$vuetify.goTo($refs.dataTable)"
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="(item, index) in props.items"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card outlined elevation="4" max-width="400">
              <v-img class="black--text align-end mb-2" :src="item.item_image">
              </v-img>
              <v-card-title>
                <div class="text-truncate">
                  {{ item.item_name }}
                </div></v-card-title
              >
              <v-card-text class="text--primary">
                <div>Price : {{ item.item_price ? item.item_price.$numberDecimal : 'No Price Detected' }} $</div>
                <div>Type : {{ item.item_type }}</div>
                <div v-if="item.item_is_new">New !!</div>
                <div v-if="item.item_available">Available</div>
                <div v-else>Not Available</div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue" text @click="linkItem(item.item_link)">
                  Link
                </v-btn>
                <v-btn
                  class="ma-2"
                  text
                  icon
                  color="gray lighten-2"
                  v-if="item.item_favorite == false"
                  @click="likeItem(item._id, item.item_favorite)"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn
                  class="ma-2"
                  text
                  icon
                  color="red lighten-2"
                  v-else
                  @click="likeItem(item._id, item.item_favorite)"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import itemService from "../../service/ItemService";
export default {
  name: "Homepage",
  data: () => ({
    allItem: 0,
    page: 1,
    items: [],
    default: [],
    filterArray: [],
  }),
  created: async function () {
    this.$store.commit("setDefaultFavorite");
    this.$store.commit("setDefaultNewItem");
    this.$store.commit("setDefaultAvailable");
    this.$store.commit("setDefaultNotAvailable");
    this.$store.commit("setDefaultSortByName");
    this.$store.commit("setDefaultSortByPrice");
    this.$store.commit("setDefaultSortByDate");
    this.$store.commit("setDefaultSortByName_re");
    this.$store.commit("setDefaultSortByPrice_re");
    this.$store.commit("setDefaultSortByDate_re");
    await itemService.getAllItems().then((result) => {
      this.default = result.data;
      this.items = result.data;
    });
    console.log(this.default);
    this.allItem = this.default.length;
  },
  methods: {
    nextPage() {
      console.log("next")
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async likeItem(id, favorite) {
      for (var i of this.items) {
        if (i._id == id) {
          i.item_favorite = !i.item_favorite;
          if (this.favoriteState) {
            let index = this.items.indexOf(i);
            this.items.splice(index, 1);
          }
        }
      }

      await itemService
        .updateFavorite({ _id: id, item_favorite: !favorite })
        .then((result) => {
          console.log(result);
        });
    },
    linkItem(url) {
      window.open(url, "_blank");
    },
    filterAll() {
      this.items = this.default;
      if (this.arrFilter.length != 0) {
        this.items = this.default.filter((items) =>
          this.arrFilter.includes(items.item_type)
        );
      }
      if (this.filterArray.indexOf("available") > -1) {
        this.items = this.items.filter((item) => item.item_available == true);
      }
      if (this.filterArray.indexOf("not available") > -1) {
          this.items = this.items.filter((item) => item.item_available == false);
        }
      if (this.filterArray.indexOf("favorite") > -1) {
        this.items = this.items.filter((item) => item.item_favorite == true);
      }
      if (this.filterArray.indexOf("newItem") > -1) {
        this.items = this.items.filter((item) => item.item_is_new == true);
      }
      if (this.sortNameState) {
        this.sortName();
      } else if (this.sortPriceState) {
        this.sortPrice();
      } else if (this.sortDateState) {
        this.sortDate();
      } else if (this.sortName_reState) {
        this.sortName_re();
      } else if (this.sortPrice_reState) {
        this.sortPrice_re();
      } else if (this.sortDate_reState) {
        this.sortDate_re();
      } else {
        this.sortDefault();
      }
    },
    sortDefault() {
      this.items.sort(function (a, b) {
        if (a._id < b._id) {
          return -1;
        }
        if (a._id > b._id) {
          return 1;
        }
        return 0;
      });
    },
    sortName() {
      console.log("test normarl")
      this.items.sort(function (a, b) {
        if (a.item_name < b.item_name) {
          return -1;
        }
        if (a.item_name > b.item_name) {
          return 1;
        }
        return 0;
      });
    },
    sortPrice() {
      console.log("test normarl")
      this.items.sort(function (a, b) {
        if (
          parseFloat(a.item_price.$numberDecimal) <
          parseFloat(b.item_price.$numberDecimal)
        ) {
          return -1;
        }
        if (
          parseFloat(a.item_price.$numberDecimal) >
          parseFloat(b.item_price.$numberDecimal)
        ) {
          return 1;
        }
        return 0;
      });
    },
    sortDate() {
      console.log("test normarl")
      this.items.sort(function (a, b) {
        if (a.item_create < b.item_create) {
          return -1;
        }
        if (a.item_create > b.item_create) {
          return 1;
        }
        return 0;
      });
    },
    sortName_re() {
      console.log("test reverse")
      this.items.sort(function (a, b) {
        if (a.item_name < b.item_name) {
          return 1;
        }
        if (a.item_name > b.item_name) {
          return -1;
        }
        return 0;
      });
    },
    sortPrice_re() {
      console.log("test reverse")
      this.items.sort(function (a, b) {
        if (
          parseFloat(a.item_price.$numberDecimal) <
          parseFloat(b.item_price.$numberDecimal)
        ) {
          return 1;
        }
        if (
          parseFloat(a.item_price.$numberDecimal) >
          parseFloat(b.item_price.$numberDecimal)
        ) {
          return -1;
        }
        return 0;
      });
    },
    sortDate_re() {
      console.log("test reverse")
      this.items.sort(function (a, b) {
        if (a.item_create < b.item_create) {
          return 1;
        }
        if (a.item_create > b.item_create) {
          return -1;
        }
        return 0;
      });
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
    arrFilter: {
      get: function () {
        return this.$store.getters.getArr;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    searchText: {
      get: function () {
        return this.$store.getters.getSearch;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    favoriteState: {
      get: function () {
        return this.$store.getters.getFavorite;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    newItemState: {
      get: function () {
        return this.$store.getters.getNewItem;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    availableState: {
      get: function () {
        return this.$store.getters.getAvailable;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    notAvailableState: {
      get: function () {
        return this.$store.getters.getNotAvailable;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    sortNameState: {
      get: function () {
        return this.$store.getters.getSortByName;
      },
      set: function (newValue) {
        return new newValue();
      },
    },
    sortPriceState: {
      get: function () {
        return this.$store.getters.getSortByPrice;
      },
      set: function (newValue) {
        return new newValue();
      },
    },
    sortDateState: {
      get: function () {
        return this.$store.getters.getSortByDate;
      },
      set: function (newValue) {
        return new newValue();
      },
    },
    sortName_reState: {
      get: function () {
        return this.$store.getters.getSortByName_re;
      },
      set: function (newValue) {
        return new newValue();
      },
    },
    sortDate_reState: {
      get: function () {
        return this.$store.getters.getSortByDate_re;
      },
      set: function (newValue) {
        return new newValue();
      },
    },
    sortPrice_reState: {
      get: function () {
        return this.$store.getters.getSortByPrice_re;
      },
      set: function (newValue) {
        return new newValue();
      },
    },
  },
  watch: {
    arrFilter: function () {
      if (this.arrFilter.length != 0) {
        this.items = this.default.filter((items) =>
          this.arrFilter.includes(items.item_type)
        );
        if (this.filterArray.indexOf("available") > -1) {
          this.items = this.items.filter((item) => item.item_available == true);
        }
        if (this.filterArray.indexOf("not available") > -1) {
          this.items = this.items.filter((item) => item.item_available == false);
        }
        if (this.filterArray.indexOf("favorite") > -1) {
          this.items = this.items.filter((item) => item.item_favorite == true);
        }
        if (this.filterArray.indexOf("newItem") > -1) {
          this.items = this.items.filter((item) => item.item_is_new == true);
        }
        if (this.sortNameState) {
          this.sortName();
        } else if (this.sortPriceState) {
          this.sortPrice();
        } else if (this.sortDateState) {
          this.sortDate();
        } else if (this.sortName_reState) {
          this.sortName_re();
        } else if (this.sortPrice_reState) {
          this.sortPrice_re();
        } else if (this.sortDate_reState) {
          this.sortDate_re();
        } else {
          this.sortDefault();
        }
      } else {
        this.items = this.default;
        this.filterAll();
      }
    },
    searchText: function () {
      if (this.searchText != "") {
        this.filterAll();
        this.items = this.items.filter((item) =>
          item.item_name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        this.filterAll();
      }
    },
    // favoriteState: function() {
    //   if (this.favoriteState) {
    //     this.items = this.default.filter((item) => item.item_favorite == true);
    //   } else {
    //     this.items = this.default;
    //   }
    // },
    // newItemState: function() {
    //   if (this.newItemState){
    //     this.items = this.default.filter((item) => item.item_is_new == true);
    //   } else {
    //     this.items = this.default
    //   }
    // },
    // availableState: function() {
    //   if(this.availableState){
    //     this.items = this.default.filter((item) => item.item_available == true);
    //   }
    //   else {
    //     this.items = this.default
    //   }
    // }
    favoriteState: function () {
      var favorite = "favorite";
      if (this.favoriteState) {
        this.filterArray.push(favorite);
      } else {
        this.filterArray.splice(this.filterArray.indexOf("favorite"), 1);
      }
    },
    newItemState: function () {
      var newItem = "newItem";
      if (this.newItemState) {
        this.filterArray.push(newItem);
      } else {
        this.filterArray.splice(this.filterArray.indexOf("newItem"), 1);
      }
    },
    availableState: function () {
      var available = "available";
      if (this.availableState) {
        this.filterArray.push(available);
      } else {
        this.filterArray.splice(this.filterArray.indexOf("available"), 1);
      }
    },
    notAvailableState: function() {
      var notAvailable = "not available"
      if (this.notAvailableState) {
        this.filterArray.push(notAvailable);
      } else {
        this.filterArray.splice(this.filterArray.indexOf("not available"), 1);
      }
    },
    filterArray: function () {
      // console.log("available" + this.filterArray.indexOf("available"))
      // console.log("favorite" + this.filterArray.indexOf("favorite"))
      // this.items = this.default
      // if (this.filterArray.indexOf("available") > -1){
      //   this.items = this.items.filter((item) => item.item_available == true);
      // }
      // if (this.filterArray.indexOf("favorite") > -1){
      //   this.items = this.items.filter((item) => item.item_favorite == true);
      // }
      // if (this.filterArray.indexOf("newItem") > -1){
      //   this.items = this.items.filter((item) => item.item_is_new == true);
      // }
      this.filterAll();
    },
    sortNameState: function () {
      this.filterAll();
    },
    sortPriceState: function () {
      this.filterAll();
    },
    sortDateState: function () {
      this.filterAll();
    },
    sortName_reState: function () {
      this.filterAll();
    },
    sortPrice_reStae: function () {
      this.filterAll();
    },
    sortDate_reState: function () {
      this.filterAll();
    },
  },
};
</script>
