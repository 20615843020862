import axios from 'axios'
import url from '../api/api'

class TimerService {
    static updateAlertTime (payload){
        try{
            return axios.put(url.timers.updateAlertTime, payload).then((result)=>{
                return result
            })
        }
        catch (err){
            console.log(err)
        }
    }
    static updateAddedTime (payload){
        try{
            return axios.put(url.timers.updateAddedTime, payload).then((result)=>{
                return result
            })
        }
        catch (err){
            console.log(err)
        }
    }
    static getTimer (){
        try{
            return axios.get(url.timers.get).then((result) => {
                return result
            })
        }
        catch(err){
            console.log(err)
        }
    }
}
export default TimerService;