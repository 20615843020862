<template>
  <v-app-bar
    fixed
    color="#cf811b"
    dark
    app
    elevate-on-scroll
  >
    <v-btn icon>
      <v-icon large color="white" @click="showDrawer">
        mdi-view-headline
      </v-icon>
    </v-btn>
    <!-- <v-app-bar-title>HomePage</v-app-bar-title> -->
    <v-text-field
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        placeholder="Search here"
        v-model="searchText"
      ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon large color="white" @click="logout"> mdi-logout </v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script>
import LoginService from "../../service/LoginService";
export default {
  name: "NavBar",
  data: () => ({
    searchText:''
  }),
  methods: {
    showDrawer() {
      this.$store.commit("showSideBarMenu");
    },
    async logout() {
      this.$swal({
        title: "ยืนยัน",
        text: "ต้องการออกระบบหรือไม่",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await LoginService.Logout().then((result) => {
              console.log(result);
            });
            window.sessionStorage.clear();
            this.$router.go();
          } catch (err) {
            console.log(err);
          }
        }
      });
    },
  },
  computed: {
    sideBarState: {
      get: function () {
        return this.$store.getters.getSideBarMenu;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  watch:{
    searchText: function() {
      this.$store.commit("setSearch", this.searchText);
    },
  }
};
</script>
<style scoped>

</style>
