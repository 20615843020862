const BASE_URL = 'https://your-outlet.work/api'
// const BASE_URL = 'http://localhost:12000'
const api = {
    getItem: `${BASE_URL}/item/getItem`,
    updateFavorite: `${BASE_URL}/item/updateFavorite`,
    login: `${BASE_URL}/login`,
    logout: `${BASE_URL}/login/logout`,
    register: `${BASE_URL}/register`,
    getUser: `${BASE_URL}/login/user`,

    coachAccount: {
        update: `${BASE_URL}/CoachAccount/updateCoachAccount`,
        get: `${BASE_URL}/CoachAccount/getCoachAccount`
    },

    linetoken:{
        update: `${BASE_URL}/LineToken/updateLineToken`,
        get: `${BASE_URL}/LineToken/getLineToken/`
    },

    timers: {
        updateAlertTime: `${BASE_URL}/Timer/updateAlertTime`,
        updateAddedTime: `${BASE_URL}/Timer/updateAddedTime`,
        get: `${BASE_URL}/Timer/getTimer`
    }
}

export default api
