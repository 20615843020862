<template>
  <v-app>
    <NavBar></NavBar>
    <Drawer></Drawer>
    <v-main>
      <Homepage></Homepage>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from "../components/Navbar/Navbar"
import Homepage from "../components/Home/HomePage"
import Drawer from "../components/Drawer/Drawer"

export default {
  name: 'Home',
  components: {
    NavBar,
    Homepage,
    Drawer
  }
}
</script>
