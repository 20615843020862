import axios from 'axios'
import url from '../api/api'

class CoachAccountService {
    static updateCoachAccount (payload){
        try{
            return axios.put(url.coachAccount.update, payload).then((result)=>{
                return result
            })
        }
        catch (err){
            console.log(err)
        }
    }
    static getCoachAccount (){
        try{
            return axios.get(url.coachAccount.get).then((result) => {
                return result
            })
        }
        catch(err){
            console.log(err)
        }
    }
}
export default CoachAccountService;
