<template>
  <v-main class="background">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card class="elevation-12" color="rgb(255,255,255,0.7)">
            <v-window v-model="page">
              <v-window-item :value="1">
                <v-form v-model="valid">
                  <v-card-text class="pa-16">
                    <h1 style="text-align: center">Sign In</h1>
                  </v-card-text>
                  <v-text-field
                    class="px-14 field"
                    label="Username"
                    counter="20"
                    v-model="username_login"
                    :rules="usernameRules"
                    @keyup.enter="login"
                  >
                  </v-text-field>
                  <v-text-field
                    class="px-14 field"
                    label="Password"
                    counter="20"
                    type="password"
                    v-model="password_login"
                    :rules="passwordRules"
                    @keyup.enter="login"
                  >
                  </v-text-field>
                  <div class="subtitle-2 pa-12" style="text-align: right">
                    Don't have any account?
                    <v-btn color="deep-black lighten-2" text @click="page++">
                      sign up
                    </v-btn>
                  </div>
                  <v-row justify="center" class="pb-10 pt-3">
                    <v-btn
                      @click="login"
                      class="white--text"
                      color="rgba(153, 51, 0, 0.5)"
                      >Login</v-btn
                    >
                  </v-row>
                </v-form>
              </v-window-item>
              <v-window-item :value="2">
                <v-form v-model="valid">
                  <v-card-text class="pa-16">
                    <h1 style="text-align: center">Sign Up</h1>
                  </v-card-text>
                  <v-text-field
                    class="px-14 field"
                    label="Username"
                    counter="20"
                    v-model="username_regis"
                    :rules="usernameRules"
                  >
                  </v-text-field>
                  <v-text-field
                    class="px-14 field"
                    label="Password"
                    type="password"
                    counter="20"
                    v-model="password_regis"
                    :rules="passwordRules"
                  >
                  </v-text-field>
                  <v-text-field
                    class="px-14 field"
                    label="Confirm password"
                    type="password"
                    counter="20"
                    v-model="confrim_password"
                    :rules="passwordRules"
                  >
                  </v-text-field>
                  <v-text-field
                    class="px-14 field"
                    label="Secretcode"
                    v-model="secretcode"
                    counter="20"
                  >
                  </v-text-field>
                  <div class="subtitle-2 pa-12" style="text-align: right">
                    Do you have account already?
                    <v-btn color="deep-black lighten-2" text @click="page++">
                      sign in
                    </v-btn>
                  </div>
                  <v-row justify="center" class="pb-10 pt-3">
                    <v-btn
                      class="white--text"
                      @click="register()"
                      color="rgba(255, 153, 0, 0.5)"
                      >Register</v-btn
                    >
                  </v-row>
                </v-form>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import LoginService from "../../service/LoginService";
import RegisterService from "../../service/RegisterService";
export default {
  name: "Login",
  data: () => ({
    page: 1,
    username_login: "",
    password_login: "",
    username_regis: "",
    password_regis: "",
    confrim_password: "",
    secretcode: "",
    valid: "",
    usernameRules: [
      (v) => !!v || "Username is required",
      (v) => (v && v.length <= 20) || "Username must be less than 8 characters",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length <= 20) || "Password must be less than 8 characters",
    ],
  }),
  methods: {
    async login() {
      if (this.username_login == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดกรอกชื่อผู้ใช้",
          icon: "error",
        });
        return 0;
      }
      if (this.password_login == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดระบุรหัสผ่าน",
          icon: "error",
        });
        return 0;
      }
      try {
        var obj = {
          username: this.username_login,
          password: this.password_login,
        };
        let result = await LoginService.userLogin(obj);
        let userdata = await LoginService.getUser();
        this.$store.commit("setUser", result.data);
        this.$store.commit("setUserData", userdata.data);
        this.$router.push({ name: "Home" });
      } catch (err) {
        this.$swal({
          title: "ผิดพลาด",
          text: "กรอกชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
          icon: "error",
        });
        console.log(err);
      }
    },
    async register() {
      if (this.username_regis == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดกรอกชื่อผู้ใช้",
          icon: "error",
        });
        return 0;
      }
      if (this.password_regis == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดระบุรหัสผ่าน",
          icon: "error",
        });
        return 0;
      }
      if (this.confrim_password == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดยืนยันรหัสผ่าน",
          icon: "error",
        });
        return 0;
      }
      if (this.password_regis != this.confrim_password) {
        this.$swal({
          title: "ผิดพลาด",
          text: "รหัสผ่านไม่สอดคล้องกัน",
          icon: "error",
        });
        return 0;
      }
      if (this.secretcode == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดกรอก Secretcode",
          icon: "error",
        });
        return 0;
      }
      var obj = {
        username: this.username_regis,
        password: this.password_regis,
        secretcode: this.secretcode,
      };
      await RegisterService.regis(obj).then((result) => {
        console.log(result.data);
        if (result.data == "Username Duplicate") {
          this.$swal({
            title: "ผิดพลาด",
            text: "ชื่อผู้ใช้มีซ้ำในระบบ",
            icon: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Close",
          });
        } else if (result.data == "Invalid SecretCode") {
          this.$swal({
            title: "ผิดพลาด",
            text: "Secretcode ไม่ถูกต้อง",
            icon: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Close",
          });
        } else {
          this.$swal({
            title: "สำเร็จ",
            text: "สร้างบัญชีสำเร็จ",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Close",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$router.go();
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.background {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 153, 0, 0.5),
    rgba(153, 51, 0, 0.5)
  );
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
</style>
