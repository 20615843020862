import axios from 'axios';
import url from "../api/api"
class LoginService {
    static userLogin (payload){
        try {
            return axios.post(url.login, payload).then((result) => {
                return result
            })
        } catch (err){
            return err
        }
    }
    static getSession (){
        return axios.get(url.login)
    }
    static Logout (){
        try {
            return axios.get(url.logout).then((result) =>{
                return result
            })
        }
        catch(err){
            return err
        }
    }
    static async getUser(){
        return await axios.get(url.getUser)
    }
}
export default LoginService;
