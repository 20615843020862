<template>
    <v-app>
        <Login></Login>
    </v-app>
</template>
<script>
import Login from '../components/Login/Login'
export default {
    name: 'LoginView',
    components: {
        Login
    }
}
</script>