import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/LoginView.vue'
import Home from '../views/Home.vue'
import LoginService from '../service/LoginService'
import store from '../store/index'

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "Home",
      component: Home
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    }
  ]
});
router.beforeEach(async (to, from, next) => {
  if (to.name != "Login" && !store.getters.getUser){
    try{
      let result = await LoginService.getSession()
      console.log(result)
      store.commit("setUser", result)
      next()
    }
    catch(err){
      console.log(err)
      next({name: "Login"})
    }
  }else if (to.name == "Login" && store.getters.getUser) {
    next({ name: "Home" });
  } else if (to.name != "Home" && store.getters.getUser) {
    next({ name: "Home" });
  }else {
    next();
  }
})
// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     components: Login
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   },

// ]

// const router = new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

export default router
