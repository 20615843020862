import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  // modules: {
  // }
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    sidebarMenu: false,
    arrFilterStore: [],
    search: "",
    favorite: false,
    newItem: false,
    available: false,
    notAvailable: false,
    sortByName: false,
    sortByName_re: false,
    sortByPrice: false,
    sortByPrice_re: false,
    sortByDate: false,
    sortByDate_re: false,
    user: "",
    userdata:{},
  },
  mutations: {
    showSideBarMenu(state) {
      state.sidebarMenu = !state.sidebarMenu;
    },
    setArrFilter(state, arr){
      state.arrFilterStore = arr
    },
    setSearch(state, text){
      state.search = text
    },
    setFavorite(state){
      state.favorite = !state.favorite
    },
    setDefaultFavorite(state){
      state.favorite = false
    },
    setNewItem(state){
      state.newItem = !state.newItem
    },
    setDefaultNewItem(state){
      state.newItem = false
    },
    setAvailable(state){
      state.available = !state.available
    },
    setDefaultAvailable(state){
      state.available = false
    },
    setNotAvailable(state){
      state.notAvailable = !state.notAvailable
    },
    setDefaultNotAvailable(state){
      state.notAvailable = false
    },
    setSortByName(state){
      state.sortByName = !state.sortByName
    },
    setSortByName_re(state){
      state.sortByName_re = !state.sortByName_re
    },
    setDefaultSortByName(state){
      state.sortByName = false
    },
    setDefaultSortByName_re(state){
      state.sortByName_re = false
    },
    setSortByPrice(state){
      state.sortByPrice = !state.sortByPrice
    },
    setSortByPrice_re(state){
      state.sortByPrice_re = !state.sortByPrice_re
    },
    setDefaultSortByPrice(state){
      state.sortByPrice = false
    },
    setDefaultSortByPrice_re(state){
      state.sortByPrice_re = false
    },
    setSortByDate(state){
      state.sortByDate = !state.sortByDate
    },
    setSortByDate_re(state){
      state.sortByDate_re = !state.sortByDate_re
    },
    setDefaultSortByDate(state){
      state.sortByDate = false
    },
    setDefaultSortByDate_re(state){
      state.sortByDate_re = false
    },
    setUser(state, data){
      state.user = data
    },
    setUserData(state, data){
      state.userdata = data
    },
  },
  getters: {
    getSideBarMenu: state => {
      return state.sidebarMenu;
    },
    getArr: state =>{
      return state.arrFilterStore
    },
    getSearch: state => {
      return state.search
    },
    getFavorite: state => {
      return state.favorite
    },
    getNewItem: state => {
      return state.newItem
    },
    getAvailable: state => {
      return state.available
    },
    getNotAvailable: state => {
      return state.notAvailable
    },
    getSortByName: state => {
      return state.sortByName
    },
    getSortByPrice: state => {
      return state.sortByPrice
    },
    getSortByDate: state => {
      return state.sortByDate
    },
    getSortByName_re: state => {
      return state.sortByName_re
    },
    getSortByPrice_re: state => {
      return state.sortByPrice_re
    },
    getSortByDate_re: state => {
      return state.sortByDate_re
    },
    getUserData: state => {
      return state.userdata
    },
    getUser: state => {
      return state.user ? true : false
    },
  },
});
