import axios from 'axios';
import url from "../api/api"
class ItemService {
    static getAllItems (){
        try {
            return axios.get(url.getItem).then((result) => {
                return result
            })
        } catch (err){
            return err
        }
    }
    static updateFavorite (id) {
        try {
            return axios.put(url.updateFavorite, id).then((result) => {
                return result
            })
        } catch (err) {
            return err
        }
    }
}
export default ItemService;