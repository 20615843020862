<template>
  <v-navigation-drawer
    floating
    app
    v-model="sideBarState"
    :permanent="sideBarState"
    class="navDraw"
    width="auto"
  >
    <v-list color="rgb(161, 84, 6)">
      <v-list-item @click="showDrawer">
        <v-list-item-action>
          <v-icon>mdi-chevron-left</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <h3 class="textCoach white--text">{{ userData.username }}</h3>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-container class="my-3">
      <v-form>
        <p class="input_style">You're in {{ currentUsername }}</p>
        <v-btn
          @click="showCoach = !showCoach"
          plain
          class="black--text mt-2"
          small
          >Coach Account</v-btn
        >
        <v-expand-transition>
          <div v-if="showCoach">
            <v-text-field
              disabled
              class="mx-2"
              label="Coach Username"
              v-if="editCoach == false"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              label="Coach Username"
              v-model="coachUsername"
              @keyup.enter="saveCoach"
              v-else
            >
            </v-text-field>
            <v-text-field
              type="password"
              label="Coach Password"
              class="mx-2"
              disabled
              v-if="editCoach == false"
            >
            </v-text-field>
            <v-text-field
              v-else
              type="password"
              label="Coach Password"
              v-model="coachPassword"
              class="mx-2"
              @keyup.enter="saveCoach"
            >
            </v-text-field>
            <v-btn class="mx-2" @click="saveCoach" v-if="editCoach == true"
              >Save</v-btn
            >
            <v-btn class="mx-2" @click="closeCoach" v-if="editCoach == true"
              >close</v-btn
            >
            <v-btn class="mx-2" @click="editCoach = true" v-else>Edit</v-btn>
          </div>
        </v-expand-transition>
      </v-form>
    </v-container>
    <v-container>
      <v-form>
        <v-btn class="rgb(0,0,0)--text" plain small @click="showToken('normal')">
          Line Alert Token
        </v-btn>
        <v-expand-transition>
          <div v-if="showInputToken">
            <v-text-field
              disabled
              label="Token"
              v-model="alert_token"
              v-if="editToken == false"
            >
            </v-text-field>
            <v-text-field label="Token" v-model="alert_token" v-else> </v-text-field>
            <v-btn class="mx-2" @click="addToken('normal')" v-if="editToken == true"
              >Add</v-btn
            >
            <v-btn
              class="mx-2"
              @click="(showInputToken = !showInputToken), (editToken = false)"
              v-if="editToken == true"
              >Close</v-btn
            >
            <v-btn class="mx-2" v-else @click="editToken = true">Edit</v-btn>
          </div>
        </v-expand-transition>
      </v-form>
    </v-container>
    <v-container>
      <v-form>
        <v-btn class="rgb(0,0,0)--text" plain small @click="showToken('price')">
          Line Alert Price Token
        </v-btn>
        <v-expand-transition>
          <div v-if="showInputToken2">
            <v-text-field
              disabled
              label="Token"
              v-model="price_token"
              v-if="editToken2 == false"
            >
            </v-text-field>
            <v-text-field label="Token" v-model="price_token" v-else> </v-text-field>
            <v-btn class="mx-2" @click="addToken('price')" v-if="editToken2 == true"
              >Add</v-btn
            >
            <v-btn
              class="mx-2"
              @click="(showInputToken2 = !showInputToken2), (editToken2 = false)"
              v-if="editToken2 == true"
              >Close</v-btn
            >
            <v-btn class="mx-2" v-else @click="editToken2 = true">Edit</v-btn>
          </div>
        </v-expand-transition>
      </v-form>
    </v-container>
    <v-divider class="mx-3 mb-3"></v-divider>
    <v-container>
      <v-btn
        @click="showFilter = true"
        v-if="!showFilter"
        plain
        class="black--text"
        >Show Filter</v-btn
      >
      <v-btn
        @click="showFilter = false"
        v-if="showFilter"
        plain
        class="red--text"
        >Hide Filter</v-btn
      >
    </v-container>
    <v-expand-transition>
      <div v-if="showFilter">
        <v-container>
          <v-form>
            <v-btn
              class="ma-2 rgb(0,0,0)--text"
              plain
              small
              @click="showText = !showText"
            >
              Search
            </v-btn>
            <v-expand-x-transition>
              <v-text-field
                v-if="showText"
                label="Seach Text"
                v-model="searchText"
              >
              </v-text-field>
            </v-expand-x-transition>
          </v-form>
        </v-container>
        <v-container>
          <v-form>
            <v-btn class="ma-2" small plain @click="showType = !showType">
              Type
            </v-btn>
            <v-expand-transition>
              <div v-if="showType">
                <v-row>
                  <v-col>
                    <div v-for="(item, index) in items" :key="index">
                      <v-layout>
                        <v-checkbox
                          :label="item.name"
                          name="item.name"
                          v-model="item.check"
                          @click="checkChange(item.name)"
                          class="resize_checkbox"
                        ></v-checkbox>
                      </v-layout>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-form>
        </v-container>
        <v-container v-if="favoriteState == false">
          <v-form>
            <v-btn class="ma-2" small plain @click="filterFavorite()">
              Favorite
            </v-btn>
          </v-form>
        </v-container>
        <v-container v-else>
          <v-form>
            <v-btn class="ma-2 red--text" small plain @click="filterFavorite()">
              Favorite
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </v-form>
        </v-container>
        <v-container v-if="newItemState == false">
          <v-form>
            <v-btn class="ma-2" small plain @click="filterNewItem()">
              New items</v-btn
            >
          </v-form>
        </v-container>
        <v-container v-else>
          <v-form>
            <v-btn class="ma-2 red--text" small plain @click="filterNewItem()">
              New items
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </v-form>
        </v-container>
        <v-container>
          <v-combobox label="Status" :items="itemsStatus" v-model="typeStatus">
          </v-combobox>
        </v-container>
        <v-container>
          <v-form>
            <v-btn class="ma-2" small plain @click="resetFilter()">
              Reset filter</v-btn
            >
          </v-form>
        </v-container>
      </div>
    </v-expand-transition>
    <v-divider class="mx-3 my-5"></v-divider>
    <v-container>
      <v-btn @click="showSort = true" v-if="!showSort" plain class="black--text"
        >Show Sort</v-btn
      >
      <v-btn @click="showSort = false" v-if="showSort" plain class="red--text"
        >Hide Sort</v-btn
      >
    </v-container>
    <v-expand-transition>
      <div v-if="showSort">
        <v-container>
          <v-combobox label="Sort" :items="itemsSort" v-model="typeSort">
          </v-combobox>
        </v-container>
      </div>
    </v-expand-transition>
    <v-divider class="mx-3 my-5"></v-divider>
    <v-container>
      <v-combobox
        v-model="timeSelect"
        :items="times"
        label="Set Notification Frequency"
      >
      </v-combobox>
    </v-container>
    <v-container>
      <v-combobox
        v-model="timeSelectAdd"
        :items="times"
        label="Set Add to Cart Frequency"
      >
      </v-combobox>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import CoachAccountService from "../../service/CoachAccountServices";
import LineTokenService from "../../service/LineTokenService";
import TimerService from "../../service/TimerService";
import ItemService from '../../service/ItemService'
export default {
  name: "Drawer",
  data: () => ({
    arr: [],
    itemsSort: [
      { text: "Name:A > Z" },
      { text: "Name:Z > A" },
      { text: "Price:low > high" },
      { text: "Price:high > low" },
      { text: "Created Date:Old > New" },
      { text: "Created Date:New > Old" },
    ],
    times: [
      { text: "0 minutes", millisec: 0 },
      { text: "15 minutes", millisec: 900000 },
      { text: "30 minutes", millisec: 1800000 },
      { text: "1 hours", millisec: 3600000 },
      { text: "2 hours", millisec: 7200000 },
      { text: "3 hours", millisec: 10800000 },
      { text: "6 hours", millisec: 21600000 },
      { text: "12 hours", millisec: 43200000 },
      { text: "24 hours", millisec: 86400000 },
    ],
    itemsStatus: [
      { text: "All" },
      { text: "Available" },
      { text: "Not available" },
    ],
    editCoach: false,
    editToken: false,
    editToken2:false,
    coachUsername: "",
    coachPassword: "",
    showCoach: false,
    showText: false,
    showInputToken: false,
    showInputToken2:false,
    showType: false,
    showFilter: false,
    showSort: false,
    typeSort: "",
    typeStatus: "",
    like: false,
    searchText: "",
    alert_token: "",
    price_token:"",
    timeSelect: "",
    timeSelectAdd: "",
    currentUsername: "",
    accounts: [],
    checkAlert: -1,
    checkAlertAdd: -1,
    items: [],
  }),
  created: async function() {
    this.getCoachAccount();
    this.getTimer();
    this.getTypeList()
  },
  methods: {
    async getTypeList(){
      try{
        let result = (await ItemService.getAllItems()).data
        let arr = new Set()
        result.map(item => {
          arr.add(item.item_type)
        })
        for (let item of arr){
          this.items.push({
            name:item
          })
        }
      }
      catch(err){
        console.log(err)
      }
    },
    async getLineToken(type) {
      try {
        let result = await LineTokenService.getLineToken(type);
        if (type == 'price'){
          this.price_token = result.data.token
        }
        else if (type == 'normal'){
          this.alert_token = result.data.token
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async getTimer() {
      try {
        await TimerService.getTimer().then((result) => {
          console.log(result);
          for (var i of this.times) {
            if (i.millisec == result.data.alert_time) {
              this.timeSelect = i.text;
              break;
            }
          }
          for (var j of this.times) {
            if (j.millisec == result.data.added_time) {
              this.timeSelectAdd = j.text;
              break;
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getCoachAccount() {
      try {
        await CoachAccountService.getCoachAccount().then((result) => {
          console.log(result);
          this.currentUsername = result.data.username;
        });
      } catch (err) {
        console.log(err);
      }
    },
    showDrawer() {
      console.log(this.$store);
      this.$store.commit("showSideBarMenu");
    },
    checkChange(name) {
      let add = 0;
      for (var i of this.arr) {
        if (name == i) {
          let index = this.arr.indexOf(name);
          this.arr.splice(index, 1);
          add = 1;
          this.$store.commit("setArrFilter", this.arr);
          break;
        }
      }
      if (add == 0) {
        this.arr.push(name);
        this.$store.commit("setArrFilter", this.arr);
      }
    },
    filterFavorite() {
      this.$store.commit("setFavorite");
    },
    filterNewItem() {
      this.$store.commit("setNewItem");
    },
    filterAvailable() {
      this.$store.commit("setAvailable");
    },
    sortByName() {
      this.$store.commit("setSortByName");
      this.$store.commit("setDefaultSortByPrice");
      this.$store.commit("setDefaultSortByDate");
      this.$store.commit("setDefaultSortByName_re");
      this.$store.commit("setDefaultSortByPrice_re");
      this.$store.commit("setDefaultSortByDate_re");
    },
    sortByPrice() {
      this.$store.commit("setSortByPrice");
      this.$store.commit("setDefaultSortByName");
      this.$store.commit("setDefaultSortByDate");
      this.$store.commit("setDefaultSortByName_re");
      this.$store.commit("setDefaultSortByPrice_re");
      this.$store.commit("setDefaultSortByDate_re");
    },
    sortByDate() {
      this.$store.commit("setSortByDate");
      this.$store.commit("setDefaultSortByPrice");
      this.$store.commit("setDefaultSortByName");
      this.$store.commit("setDefaultSortByName_re");
      this.$store.commit("setDefaultSortByPrice_re");
      this.$store.commit("setDefaultSortByDate_re");
    },
    sortByName_re() {
      this.$store.commit("setSortByName_re");
      this.$store.commit("setDefaultSortByName");
      this.$store.commit("setDefaultSortByPrice");
      this.$store.commit("setDefaultSortByDate");
      this.$store.commit("setDefaultSortByPrice_re");
      this.$store.commit("setDefaultSortByDate_re");
    },
    sortByPrice_re() {
      this.$store.commit("setSortByPrice_re");
      this.$store.commit("setDefaultSortByName");
      this.$store.commit("setDefaultSortByPrice");
      this.$store.commit("setDefaultSortByDate");
      this.$store.commit("setDefaultSortByName_re");
      this.$store.commit("setDefaultSortByDate_re");
    },
    sortByDate_re() {
      this.$store.commit("setSortByDate_re");
      this.$store.commit("setDefaultSortByPrice");
      this.$store.commit("setDefaultSortByName");
      this.$store.commit("setDefaultSortByDate");
      this.$store.commit("setDefaultSortByName_re");
      this.$store.commit("setDefaultSortByPrice_re");
    },
    async saveCoach() {
      if (this.coachUsername == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดกรอกชื่อผู้ใช้งาน",
          icon: "error",
          confirmButtonText: "Yes",
        });
        return 0;
      }
      if (this.coachPassword == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดกรอกรหัสผ่าน",
          icon: "error",
          confirmButtonText: "Yes",
        });
        return 0;
      }
      try {
        var obj = {
          username: this.coachUsername,
          password: this.coachPassword,
        };
        this.$swal({
          title: "ยืนยัน",
          text: "ต้องการจะเปลี่ยน CoachAccount หรือไม่",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          icon: "question",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "กำลังแก้ไข....",
              text: "โปรดรอ....",
              imageUrl: "https://i.imgur.com/zasbdQB.gif",
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            await CoachAccountService.updateCoachAccount(obj)
            this.$swal({
                title: "สำเร็จ",
                text: "Update CoachAccount สำเร็จ",
                icon: "success",
                confirmButtonText: "Close",
            });
            this.showCoach = false;
            this.getCoachAccount();
          }
        });
      } catch (err) {
        console.log(err);
        this.$swal({
          title: "ผิดพลาด",
          text: "แก้ไข CoachAccount ไม่สำเร็จ",
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    },
    closeCoach() {
      this.showCoach = !this.showCoach;
      this.coachPassword = "";
      this.coachUsername = "";
      this.editCoach = false;
    },
    showToken(type) {
      if (type == 'normal'){
        this.showInputToken = !this.showInputToken;
      }
      else if (type == 'price'){
        this.showInputToken2 = !this.showInputToken2;
      }      
      this.getLineToken(type);
    },
    async addToken(type) {
      if (this.token == "") {
        this.$swal({
          title: "ผิดพลาด",
          text: "โปรดกรอก Token",
          icon: "error",
          confirmButtonText: "Yes",
        });
        return 0;
      }
      try {
        this.$swal({
          title: "ยืนยัน",
          text: "ต้องการเปลี่ยน Token หรือไม่",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          icon: "question",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await LineTokenService.updateLineToken({ token: type == 'normal' ? this.alert_token : this.price_token, type:type }).then(
              (result) => {
                console.log(result);
                this.$swal({
                  title: "สำเร็จ",
                  text: "Update token สำเร็จ",
                  icon: "success",
                  confirmButtonText: "Close",
                });
              }
            );
            this.showInputToken = false;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    resetFilter() {
      this.$store.commit("setDefaultFavorite");
      this.$store.commit("setDefaultNewItem");
      this.$store.commit("setDefaultAvailable");
      this.$store.commit("setDefaultNotAvailable");
      this.typeStatus = "";
    },
  },
  computed: {
    sideBarState: {
      get: function() {
        return this.$store.getters.getSideBarMenu;
      },
      set: function(newValue) {
        return newValue;
      },
    },
    favoriteState: {
      get: function() {
        return this.$store.getters.getFavorite;
      },
      set: function(newValue) {
        return newValue;
      },
    },
    newItemState: {
      get: function() {
        return this.$store.getters.getNewItem;
      },
      set: function(newValue) {
        return newValue;
      },
    },
    availableState: {
      get: function() {
        return this.$store.getters.getAvailable;
      },
      set: function(newValue) {
        return newValue;
      },
    },
    notAvailableState: {
      get: function() {
        return this.$store.getters.getNotAvailable;
      },
      set: function(newValue) {
        return newValue;
      },
    },
    sortNameState: {
      get: function() {
        return this.$store.getters.getSortByName;
      },
      set: function(newValue) {
        return new newValue();
      },
    },
    sortPriceState: {
      get: function() {
        return this.$store.getters.getSortByPrice;
      },
      set: function(newValue) {
        return new newValue();
      },
    },
    sortDateState: {
      get: function() {
        return this.$store.getters.getSortByDate;
      },
      set: function(newValue) {
        return new newValue();
      },
    },
    sortName_reState: {
      get: function() {
        return this.$store.getters.getSortByName_re;
      },
      set: function(newValue) {
        return new newValue();
      },
    },
    sortDate_reState: {
      get: function() {
        return this.$store.getters.getSortByDate_re;
      },
      set: function(newValue) {
        return new newValue();
      },
    },
    sortPrice_reState: {
      get: function() {
        return this.$store.getters.getSortByPrice_re;
      },
      set: function(newValue) {
        return new newValue();
      },
    },
    userData: {
      get: function() {
        return this.$store.getters.getUserData;
      },
      set: function(newValue) {
        return newValue;
      },
    },
  },
  watch: {
    searchText: function() {
      this.$store.commit("setSearch", this.searchText);
    },
    timeSelect: function() {
      this.checkAlert++;
      if (this.checkAlert > 0) {
        this.$swal({
          title: "ยืนยัน",
          text: "ต้องการเปลี่ยนเวลาแจ้งเตือน line หรือไม่",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await TimerService.updateAlertTime({
                alert_time: this.timeSelect.millisec,
              }).then((result) => {
                console.log(result);
                this.$swal({
                  title: "สำเร็จ",
                  text: "Update เวลาแจ้งเตือน line สำเร็จ",
                  icon: "success",
                  confirmButtonText: "Close",
                });
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            try {
              await TimerService.getTimer().then((result) => {
                console.log(result);
                for (var i of this.times) {
                  if (i.millisec == result.data.alert_time) {
                    this.timeSelect = i.text;
                    break;
                  }
                }
                this.checkAlert = -1;
              });
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
    timeSelectAdd: function() {
      this.checkAlertAdd++;
      if (this.checkAlertAdd > 0) {
        this.$swal({
          title: "ยืนยัน",
          text: "ต้องการเปลี่ยนเวลาเพิ่มลงตระกร้าหรือไม่",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await TimerService.updateAddedTime({
                added_time: this.timeSelectAdd.millisec,
              }).then((result) => {
                console.log(result);
                this.$swal({
                  title: "สำเร็จ",
                  text: "Update เวลาเพิ่มลงตระกร้า สำเร็จ",
                  icon: "success",
                  confirmButtonText: "Close",
                });
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            try {
              await TimerService.getTimer().then((result) => {
                console.log(result);
                for (var j of this.times) {
                  if (j.millisec == result.data.added_time) {
                    this.timeSelectAdd = j.text;
                    break;
                  }
                }
                this.checkAlertAdd = -1;
              });
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
    typeSort: function() {
      if (this.typeSort.text == "Name:A > Z") {
        this.sortByName();
      }
      if (this.typeSort.text == "Name:Z > A") {
        this.sortByName_re();
      }
      if (this.typeSort.text == "Price:low > high") {
        this.sortByPrice();
      }
      if (this.typeSort.text == "Price:high > low") {
        this.sortByPrice_re();
      }
      if (this.typeSort.text == "Created Date:Old > New") {
        this.sortByDate();
      }
      if (this.typeSort.text == "Created Date:New > Old") {
        this.sortByDate_re();
      }
    },
    typeStatus: function() {
      if (this.typeStatus.text == "Available") {
        this.$store.commit("setAvailable");
        this.$store.commit("setDefaultNotAvailable");
      } else if (this.typeStatus.text == "Not available") {
        this.$store.commit("setNotAvailable");
        this.$store.commit("setDefaultAvailable");
      } else {
        this.$store.commit("setDefaultAvailable");
        this.$store.commit("setDefaultNotAvailable");
      }
    },
  },
};
</script>
<style scoped>
.navDraw {
  background-color: rgb(255, 233, 218) !important;
}
.resize_checkbox {
  width: 1rem !important;
}
.input_style {
  font-size: 0.7rem !important;
  color: grey;
}</style
>>
