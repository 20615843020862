import axios from 'axios'
import url from '../api/api'

class LineTokenService {
    static updateLineToken (payload){
        try{
            return axios.put(url.linetoken.update, payload).then((result)=>{
                return result
            })
        }
        catch (err){
            console.log(err)
        }
    }
    static getLineToken (type){
        try{
            return axios.get(url.linetoken.get+type).then((result) => {
                return result
            })
        }
        catch(err){
            console.log(err)
        }
    }
}
export default LineTokenService;