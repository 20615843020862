import axios from 'axios';
import url from "../api/api"
class RegisterService {
    static regis (payload){
        try {
            return axios.post(url.register, payload).then((result) => {
                return result
            })
        } catch (err){
            return err
        }
    }
}
export default RegisterService;